<template>
  <v-container fluid>
    <Breadcrumbs />
    <v-layout row wrap>
      <v-flex xs12 sm12 md12>
        <v-row class="justify-center" style="margin: 0px">
          <v-col cols="12" md="4" sm="6">
            <v-card class="secondarygrad ma-5 pa-4 rounded">
              <v-card-title class="justify-center"
                ><v-icon color="primary" size="60"
                  >mdi-lock-outline</v-icon
                ></v-card-title
              >
              <v-card-text style="padding: 20px">
                <div class="row wrap" style="padding: 10px">
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      type="password"
                      solo-inverted
                      flat
                      dense
                      label="Old Password"
                      class=" mb-5"
                      hide-details="true"
                      clearable
                      v-model="reset.old"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      type="password"
                      solo-inverted
                      flat
                      dense
                      label="New Password"
                      class=" mb-5"
                      hide-details="true"
                      clearable
                      v-model="reset.new"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      type="password"
                      solo-inverted
                      flat
                      dense
                      label="Confirm New Password"
                      class=" mb-5"
                      hide-details="true"
                      clearable
                      v-model="reset.confirmNew"
                    >
                    </v-text-field>
                  </v-flex>
                </div>
              </v-card-text>
              <v-card-actions style="padding: 20px; margin-top: -20px">
                <v-spacer></v-spacer>
                <v-btn
                  color="primarygrad white--text"
                  @click="update('changePassword')"
                  >Reset</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog v-model="loader" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "../components/breadcrumbs.vue";
export default {
  data() {
    return {
      loader: false,
      reset: {
        old: "",
        new: "",
        confirmNew: "",
      },
    };
  },
  computed: {
    ...mapState(["ENDPOINT"]),
  },
  components: {
    Breadcrumbs,
  },
  created() {},
  methods: {
    // reset data after operation
    close() {
      const self = this;

      self.reset = {
        old: "",
        new: "",
        confirmNew: "",
      };

      // close loader
      self.loader = false;
    },

    async update(what) {
      const self = this;
      // initiate loader
      self.loader = true;
      var payload = {};

      switch (what) {
        case "changePassword":
          if (self.reset.new != self.reset.confirmNew) {
            self.loader = false;
            return self.$swal({
              type: "warning",
              text: "Passwords did'nt match",
            });
          }

          payload.userid = self.$store.getters.user.id;
          payload.username = self.$store.getters.user.username;
          payload.oldPassword = self.reset.old;
          payload.newPassword = self.reset.new;

          self.$http
            .put(`${this.ENDPOINT}/accounts/updatePassword`, payload)
            .then((response) => {
              self
                .$swal({
                  type: "success",
                  text: "Successful",
                })
                .then(function(isConfirm) {
                  self.logout();
                });
            })
            .catch((err) => {
              self.$swal({ type: "error", text: err.body.error.message });
            });
          break;
      }

      self.close();
    },

    logout() {
      this.$store.dispatch("setUser", false);
      window.localStorage.clear();
      window.location.href = "/";
    },
  },
};
</script>
<style scoped>
.v-divider {
  margin: 5px 0px 25px 0px;
}
</style>
